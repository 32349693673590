.function{
  top:64px;
}

.adm-popup-function-wrapper {
  .adm-popup-title{
    font-size: 14px;
    padding:12px 16px;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
  }
  .adm-popup-search{
    padding:0 16px;
    margin-bottom: 8px;
  }
  .adm-ellipsis{
    font-size: 14px;
  }
  .adm-input-element{
    font-size: 12px;
  }
  .adm-search-bar .adm-search-bar-input-box{
    background: #F8F8F8;
    border-radius: 16px;
  }
  .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input-box-icon{
    color:#666;
  }
  .adm-popup-body-wrapper {
    border-top:1px solid #F1F1F1;
    font-size: 14px;

    .adm-popup-show{
      display: block;
      margin-top: 11px;
    }
    .adm-popup-hide{
      display: none;
    }
    .adm-popup-body-left {
      padding: 0px 16px 16px;
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      border-right:1px solid #F1F1F1;
    }
    .adm-popup-body-right {
      padding: 0 0 12px;
      max-height: 100%;
      overflow-y: auto;
    }
    .adm-popup-body-collapse{
      padding:0 20px 0 0px;
    }
    .adm-popup-body-r-title{
      font-size: 14px;
      font-weight: 500;
      color: #222222;
      line-height: 17px;
      padding:12px 0;
      border-bottom: 1px solid #F1F1F1;
    }
  }
  .primary-color{
    color:#5CB8B2;
  }

  .adm-popup-footer{
    border-top:1px solid #F1F1F1;
    padding:16px;
    font-size: 16px;
  }
  .adm-popup-header-close{
    width: calc(100% - 32px);
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #CCCCCC;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
    margin: 8px auto;
  }
  .adm-popup-half-header-close{
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #CCCCCC;
    text-align: center;
    width: 100%;
    line-height: 38px;
  }
  .adm-popup-header-sure{
    color:#fff;
    background: #5CB8B2;
    border-radius: 2px;
    text-align: center;
    width: 100%;
    line-height: 40px;

  }
  .adm-popup-selects {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .adm-popup-select-length{
    margin-right: 8px;
  }
  .adm-popup-tag-active{
    background-color: rgba(92, 184, 178, 0.1);
    color: #5CB8B2;
    padding:1px 4px;
    font-size: 14px;
    line-height: 18px;
  }

  .adm-badge-wrapper{
    width: 100%;
  }

  .adm-tag {
    padding: 4px;
  }
  .adm-side-bar{
    width: 100%;
    background-color: transparent;
  }
  .adm-side-bar,
  .adm-side-bar-items{
    overflow:visible;
  }

  .adm-side-bar-item{
    padding: 10px 0px;
    line-height: 16px;
    background-color: transparent;
    position: relative;
    .adm-popup-count{
      position: absolute;
      right: -12px;
      top:1px;
    }
    &.adm-side-bar-item-active{
      font-weight: 600;
      position: relative;
    }
  }
  .adm-side-bar-item-highlight{
    left:-4px;
    height: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .adm-popup-body-selector{
    margin-bottom: 8px;
    display: inline-block;
    background: #F3F3F3;
    border-radius: 2px;
    padding:7px 12px;
    line-height: 18px;
    text-align: center;
    margin-right: 8px;
    &.active{
      background-color: rgba(92, 184, 178, 0.1);
      color: #5CB8B2;
    }
  }
}
.adm-action-search-wrapper {
  --z-index: var(--adm-popup-z-index, 1000);
  position: fixed;
  z-index: calc(var(--z-index) + 19);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  width: 100%;
  height: 100%;
  .adm-action-search-body{
    padding: 12px;
    .adm-action-search-action {
      display: flex;
      align-items: center;
    }
    .action-button-primary {
      color: var(--adm-color-primary);
      padding: 4px 10px;
      font-size: 16px;
    }
    .adm-action-search-back {
      padding: 4px 10px 4px 0;
      font-size: 16px;
    }
  }
}


