.city{
  top:64px;
}
.adm-popup-city-wrapper {
  .adm-tabs-content {
    padding: 0;
  }
  .adm-popup-title{
    font-size: 14px;
    padding:12px 16px;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
  }
  .adm-side-bar-item-title{
    font-size: 14px;
  }
  .adm-popup-search{
    padding:0 16px;
    margin-bottom: 8px;
  }
  .adm-input-element{
    font-size: 12px;
  }
  .adm-search-bar .adm-search-bar-input-box{
    background: #F8F8F8;
    border-radius: 16px;
  }
  .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input-box-icon{
    color:#666;
  }
  .adm-side-bar,
  .adm-side-bar-item{
    width: 100%;
    background-color: transparent;
  }

  .adm-popup-header-footer{
    border-top: solid 1px #F1F1F1;
    font-size: 16px;
    padding:16px;
    .adm-popup-header-close{
      width: calc(100% - 32px);
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #CCCCCC;
      font-size: 16px;
      line-height: 38px;
      text-align: center;
      margin: 8px auto;
    }
  }
  .adm-popup-body-selector{
    margin-bottom: 8px;
    display: inline-block;
    background: #F3F3F3;
    border-radius: 2px;
    padding:7px 12px;
    line-height: 18px;
    text-align: center;
    margin-right: 8px;
    &.active{
      background-color: rgba(92, 184, 178, 0.1);
      color: #5CB8B2;
    }
  }
  .adm-popup-body-wrapper {
    height: calc(100vh - 64px - 84px - 42px - 88px);
    .adm-popup-body-left {
      padding: 4px 0 4px 8px;
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      border-right:1px solid #F1F1F1;
    }
    .adm-popup-body-right {
      padding: 12px 0;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      overflow-y: auto;
    }
  }
  .adm-popup-selects {
    padding: 4px 8px;
  }
  .adm-tabs-tab {
    font-size: 16px;
  }
  .adm-tag {
    padding: 4px;
  }
}
.adm-action-search-wrapper {
  --z-index: var(--adm-popup-z-index, 1000);
  position: fixed;
  z-index: calc(var(--z-index) + 19);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  width: 100%;
  height: 100%;
  .adm-action-search-body{
    padding: 12px;
    .adm-action-search-action {
      display: flex;
      align-items: center;
    }
    .action-button-primary {
      color: var(--adm-color-primary);
      padding: 4px 10px;
      font-size: 16px;
    }
    .adm-action-search-back {
      padding: 4px 10px 4px 0;
      font-size: 16px;
    }
  }
}
