body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF;

  --adm-color-primary: #5cb8b2;
}

.primary-color{
  color:#5CB8B2;
}
.all-page {
  height: 100%;
  background-color: #FFF;

  &-body {
    overflow-y: auto;
    max-height: calc(100% - 45px);
  }

  .adm-nav-bar {
    background: #ffffff;
    //--border-bottom: solid 1px var(--adm-border-color);
  }
}

.submit-button {
  left: 16px;
  right: 16px;
  position: fixed;
  bottom: 32px;
  width: calc(100vw - 32px);
  line-height: 44px;
  height: 44px;
  padding:0;
  &:before{
    display: none;
  }
}
.count{
  text-align: right;
    margin-top: 20px;
}

.adm-picker{
  --item-height:48px !important;
}
.adm-avatar{
  border-radius: 50%;
}

.upload-box{
  position: relative;
  .upload-input{
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
.react-form__field-error {
  display: none;
}
.react-form .adm-list-item.is-req .adm-list-item-title:before{
  color: #d14343;
  content: "\e684";
  display: inline-block;
  font-family: iconfont!important;
  margin-right: 0;
  position: static;
  -webkit-transform: translateX(0) scale(.7);
  transform: translateX(0) scale(.7);
}
.react-form{
  padding-bottom: 100px;
  .adm-stepper{
    width: 100%;
    .adm-stepper-input{
      background-color: transparent;
    }
  }
  .adm-list-header{
    padding:32px 24px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #222222;
    line-height: 20px;
  }
  .adm-list-card{
    margin:0;
  }
  .adm-list-item-content-main{
    padding:16px 0;
  }
  .adm-list-item-title{
    font-size: 14px !important;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .react-form__field-input,
  .adm-input-element{
    font-size: 16px !important;
    font-weight: 400;
    color: #222222;
    line-height: 22px;
  }
  .adm-selector-item{
    min-width: 80px;
    &.adm-selector-item-active{
      background-color: #5CB8B2;
      color:#fff;
    }
  }
  .adm-list-header{
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }
  .adm-list-item{
    padding:0 24px;
    .adm-list-item-content{
      padding:0;
    }
    .adm-list-item-content-arrow{
      position: absolute;
      right:22px;
    }
    &:last-child{
      .adm-list-item-content{
        border-bottom:  solid 1px #eeeeee;
      }
    }
  }
}
.react-form__field-component.adm-popup{
  .adm-popup-body{
    width: 100%;
  }
}
.select-box{
  .react-form .adm-list-item-title{
    margin-bottom: 0;
  }
  .adm-list-item-content-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .adm-list-item-content{
    border:none !important;
  }
}

.salary-range{
  .adm-picker-view {
    .adm-picker-view-column-item {
      padding: 0;
    }

    .adm-picker-view-column:nth-child(3){
      display: none;
    }
  }

}